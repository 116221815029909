<template>
  <div class="h-full">
    <OtpVerification
      v-if="form.otpSent"
      :work-email="form.data.work_email.value"
      :otp-verification-id="form.data.otp_verification_id.value"
      @back="clearForm"
      @update-otp-verification-id="updateOtpVerificationId"
      @verify-otp="completeVerification"
    />
    <OtpRequest
      v-else
      @back="emitBackEvent"
      @request-otp="navigateToOtpVerification"
    />
  </div>
</template>

<script>
export default {
  name: 'WorkEmail',
  components: {
    OtpRequest: require('./OtpRequest').default,
    OtpVerification: require('./OtpVerification').default,
  },
  data() {
    return {
      form: this.$options.basicForm([
        'work_email',
        'otp',
        'otp_verification_id',
      ], {
        otpSent: false,
      }),
    };
  },
  methods: {
    clearForm() {
      this.form = this.resetForm(this.form);
    },
    completeVerification() {
      this.$success({
        title: 'Work Email Verified',
        body: 'Your account is under review and will be active once approved.',
        button: 'Okay',
        onClose: () => {
          this.$emit('complete-work-email-verification', {});
        }
      });
    },
    emitBackEvent() {
      this.$emit('back');
    },
    navigateToOtpVerification({ workEmail, otpVerificationId }) {
      this.form.data.work_email.value = workEmail;
      this.form.data.otp_verification_id.value = otpVerificationId;
      this.form.otpSent = true;
    },
    updateOtpVerificationId({ otpVerificationId }) {
      this.form.data.otp_verification_id.value = otpVerificationId;
    }
  },
};
</script>
