<template>
  <div class="flex flex-col flex-grow h-full">
    <h6 class="mb-10">Account Verification Required</h6>

    <p class="mb-2">
      Enter the verification code sent to <br>
      <b class="font-bold">{{ workEmail }}</b>
    </p>

    <p class="mb-10">
      Please be patient to receive the code
    </p>

    <form @submit.prevent="submit" class="flex flex-col flex-grow">
      <input-split
        class="mb-16"
        v-model="form.data.otp.value"
      />
      <div
        v-if="getFirstError('otp')"
        class="form-group-2-text mb-10 -mt-12 text-red-500"
      >
        {{ getFirstError('otp') }}
      </div>

      <p v-if="timer.isDone" class="text-center mb-10">
        <sm-loader v-if="form.resending" />
        <span v-else>
          Didn’t get code?
          <a
            href="#!"
            class="font-bold text-blue-500 underline"
            @click.prevent="resendOtp"
          >
            Resend
          </a>
        </span>
      </p>

      <p v-else class="mb-10 text-center">
        Resend in {{ timer.time }} secs
      </p>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="flex gap-4 mt-auto">
        <button
          type="button"
          class="btn btn-gray-soft btn-md relative"
          :disabled="form.loading || form.resending"
          @click="back"
        >
          <span>Back</span>
          <i class="absolute inline-flex left-20px top-1/2" style="transform: translateY(-50%);">
            <ion-icon name="arrow-back-outline" />
          </i>
        </button>
        <button
          type="submit"
          class="btn btn-blue btn-md mt-auto relative w-full"
          :disabled="form.loading || form.resending"
        >
          <sm-loader class="sm-loader-white" v-if="form.loading" />
          <span v-else>Proceed</span>
          <i class="absolute inline-flex right-20px top-1/2" style="transform: translateY(-50%);">
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>

  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'WorkEmailOtpVerification',
  props: {
    workEmail: {
      type: String,
      required: true,
    },
    otpVerificationId: {
      type: [String, Number],
      default: null,
    }
  },
  data() {
    return {
      form: this.$options.basicForm([
        'work_email',
        'otp',
        'otp_verification_id',
      ], { resending: false }),
      timer: {
        time: 30,
        interval: null,
        isDone: false
      }
    };
  },
  computed: {
    otpValue() {
      return this.form.data.otp.value;
    },
  },
  watch: {
    otpValue(value) {
      if (value.length === 6) {
        this.submit();
      }
    },
    otpVerificationId(otpVerificationId) {
      this.form.data.otp_verification_id.value = otpVerificationId;
    },
  },
  mounted() {
    this.startCountDown();
    this.mapData();
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    back() {
      this.$emit('back');
    },
    mapData() {
      this.form.data.work_email.value = this.workEmail;
      this.form.data.otp_verification_id.value = this.otpVerificationId;
    },
    startCountDown() {
      this.stopCountDown(this.timer);

      this.timer.isDone = false;
      this.timer.interval = setInterval(() => {
        this.timer.time -= 1;
        if (this.timer.time <= 0) {
          this.stopCountDown(this.timer);
          this.timer.isDone = true;
          this.timer.time = 60;
        }
      }, 1000);
    },
    stopCountDown() {
      clearTimeout(this.timer.interval);
    },
    async resendOtp() {
      this.form.error = null;
      this.form.resending = true;
      await this.sendRequest('personal.onboarding.workEmail.requestOtp', {
        data: this.getFormData(),
        success: (response) => {
          this.startCountDown();
          const {
            data: { otp_verification_id: otpVerificationId }
          } = response.data;
          this.$emit('update-otp-verification-id', { otpVerificationId });

          this.form.data.otp.value = '';
          this.form.data.otp.errors = null;
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.resending = false;
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.setLoading();
      await this.sendRequest('personal.onboarding.workEmail.verifyOtp', {
        data: this.getFormData(),
        success: (response) => {
          const { data: { user } } = response.data;
          this.updateUser(user)
            .then(() => {
              this.$emit('verify-otp', { user });
            });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.setLoading(false);
    },
  },
}
</script>
