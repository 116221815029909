<template>
  <div class="flex flex-col h-full">
    <h6 class="mb-10">
      Verify with workplace email
    </h6>

    <p class="font-semibold mb-5">
      Enter workplace email below
    </p>

    <form
      class="flex flex-col flex-grow"
      @submit.prevent="submit"
    >
      <form-group
        v-model="form.data.work_email.value"
        name="work_email"
        :form="form"
        type="email"
      >
        Workplace email address
      </form-group>
      <div class="flex -mt-2 mb-10">
        <span class="alert-icon bg-blue-500 mr-2 text-white">
          <ion-icon name="alert-outline" />
        </span>
        <span class="text-xs">
          Please note that your work email is strictly to verify your account.
          <br>
          We will not contact you via your work email.
        </span>
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="flex gap-4 mt-auto">
        <button
          type="button"
          class="btn btn-gray-soft btn-md relative"
          :disabled="form.loading"
          @click="back"
        >
          <span>Back</span>
          <i
            class="absolute inline-flex left-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-back-outline" />
          </i>
        </button>
        <button
          type="submit"
          class="btn btn-blue btn-md mt-auto relative w-full"
          :disabled="form.loading"
        >
          <sm-loader
            v-if="form.loading"
            class="sm-loader-white"
          />
          <span v-else>Proceed</span>
          <i
            class="absolute inline-flex right-20px top-1/2"
            style="transform: translateY(-50%);"
          >
            <ion-icon name="arrow-forward-outline" />
          </i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'WorkEmailOtpRequest',
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'work_email', rules: 'required|email|privateEmail' }
      ])
    }
  },
  methods: {
    back() {
      this.$emit('back');
    },
    async submit() {
      if (!this.validateForm(this.form)) return false;

      this.form.error = null;
      this.form.setLoading();
      await this.sendRequest('personal.onboarding.workEmail.requestOtp', {
        data: this.getFormData(),
        success: (response) => {
          const {
            data: { otp_verification_id: otpVerificationId }
          } = response.data;
          const workEmail = this.getFormValue('work_email');
          this.$emit('request-otp', { workEmail, otpVerificationId });
        },
        error: (error) => {
          this.form.error = error;
          this.mapFormErrors(this.form, error?.response?.data?.errors);
        }
      });
      this.form.setLoading(false);
    },
  },
};
</script>
